import React from "react"
import clsx from "clsx"

import * as styles from "./FormInputText.module.scss"

function FormInputText({ label, value, error, autoFocus, onChange }) {
  return (
    <div className={clsx(styles.input, { [styles.error]: !!error })}>
      <label className={styles.inputLabel}>
        {label}
        <span className={styles.inputLabelError}>{error}</span>
      </label>
      <input
        placeholder="Type here..."
        value={value}
        onChange={onChange}
        className={styles.inputField}
        autoFocus={autoFocus}
      />
    </div>
  )
}
export default FormInputText
