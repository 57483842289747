import React from "react"
import * as styles from "./DotBG.module.scss"

export default function DotBG({
  reverse,
  className = "",
  offset = 0,
  height = "100%",
}) {
  return (
    <div
      style={{ height, top: offset }}
      className={`${styles.dotBg} ${className} ${
        reverse ? styles.reverse : ""
      }`}
    >
      <div className={`${styles.dotBgOverlay}`} />
    </div>
  )
}
