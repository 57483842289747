import React from "react"
import ImageRow from "../../../Features/components/ImageRow/ImageRow"
import Row from "../../../components/Row/Row"
import PartnerTechnology from "@src/icons/v2/partner-technology.png"
import * as styles from "./Technology.module.scss"

const Technology = () => {
  return (
    <section className={styles.service}>
      <Row className={styles.row}>
        <ImageRow
          title="Technology Partner"
          subtitle={
            <div>
              <p>
                As a Technology Partner, you'll collaborate on building
                integrations with Canonic and work together to drive adoption
                among our shared customers.
              </p>
              <ul>
                <li>
                  Gain free development instances, technical support, and
                  marketing benefits.
                </li>
                <li>
                  Work closely with a Partner Manager on strategy alignment and
                  marketing initiatives.
                </li>
                <li>
                  Stand out with unique tools integral to the way our customers
                  work.
                </li>
              </ul>
            </div>
          }
          icon={PartnerTechnology}
          className={styles.technologySolution}
          borderless
        />
      </Row>
    </section>
  )
}

export default Technology
