import React from "react"

const MIN_WIDTH = 1100
export default function useMobile() {
  const [isMobile, setIsMobile] = React.useState()

  React.useEffect(() => {
    const handleSet = () => setIsMobile(window.innerWidth < MIN_WIDTH)
    window.addEventListener("resize", handleSet)
    return () => window.removeEventListener("resize", handleSet)
  }, [])

  return isMobile
}
