import React from "react"
import ImageRow from "../../../Features/components/ImageRow/ImageRow"
import Row from "../../../components/Row/Row"
import Logo1 from "@src/icons/v2/logo2.svg"
import Logo2 from "@src/icons/v2/logo1.svg"
import Logo3 from "@src/icons/v2/logo4.svg"
import Logo4 from "@src/icons/v2/logo3.svg"
import * as styles from "./Service.module.scss"
import ClientStrip from "../../../../V3/Home/sections/ClientStrip"

const Service = () => {
  return (
    <section className={styles.service}>
      <Row className={styles.row}>
        <ImageRow
          title="Service Partner"
          subtitle={
            <div>
              <p>
                As our primary services division, you'll provide exceptional
                consulting, sales, and technical services to help our joint
                customers quickly create custom business apps.
              </p>
              <ul>
                <li>Enjoy free support to ensure you never get blocked.</li>
                <li>Access training materials for improved efficiency.</li>
                <li>
                  Receive sales and marketing support to ensure your success.
                </li>
              </ul>
            </div>
          }
          icon={() => {
            return <ClientStrip />
          }}
          className={styles.serviceSolution}
          reverse
          borderless
        />
      </Row>
    </section>
  )
}

export default Service
