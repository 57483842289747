import React from "react"
import Row from "../../../components/Row"
import { Link } from "gatsby"
import * as styles from "./Join.module.scss"

const Join = () => {
  const appUrl = "https://calendly.com/canonic/30min"

  return (
    <Row className={styles.row}>
      <section className={styles.splash}>
        <div className={styles.splashText}>
          <h1 className={styles.splashTextTitle}>
            We are ready to achieve remarkable success together as a team. Are
            you?
          </h1>
          <p className={styles.splashTextSubtitle}>
            Join our Partnership Program today!
          </p>
          <div className={styles.splashTextCtas}>
            <Link
              to="/partner-program#partnerize-form"
              className={styles.splashTextCtasButton}
            >
              Get In Touch
            </Link>
          </div>
        </div>
      </section>
    </Row>
  )
}

export default Join
