import React from "react"

import Row from "../../../components/Row/Row"
import Column from "../../../components/Column/Column"

import FeatureOne from "@src/icons/v2/partner-features-2.svg"
import FeatureTwo from "@src/icons/v2/partner-features-1.svg"
import FeatureThree from "@src/icons/v2/partner-features-3.svg"
import FeatureFour from "@src/icons/v2/partner-features-4.svg"
import * as styles from "./Features.module.scss"
import { Link } from "gatsby"
const Features = () => {
  return (
    <section>
      <Row className={styles.whyCards}>
        <h2 className={styles.whyCardsTitle}>
          Serve quicker, faster, and better with Canonic
        </h2>
        <div className={styles.whyCardsText}>
          <Column.Wrap numPerRow={2}>
            <Column
              title="Accelerate Business Automation"
              icon={FeatureOne}
              description="Our intuitive drag-and-drop interface empowers you to automate repetitive tasks and streamline complex business processes in a matter of minutes."
            />
            <Column
              title="Expand Your Product Portfolio"
              icon={FeatureTwo}
              description="Empower your customers with a comprehensive solution that optimizes their operations, boosts productivity, and delivers tangible results."
            />
            <Column
              title="Unlock New Revenue Streams"
              icon={FeatureThree}
              description="Access a wealth of resources, training, and support to unlock new revenue streams and maximize your profitability."
            />
            <Column
              title="Collaborative Partnership"
              icon={FeatureFour}
              description="Benefit from dedicated account management, co-marketing, and our unwavering commitment to mutual growth."
            />
          </Column.Wrap>
          <div className={styles.whyCtas}>
            <Link
              to="/partner-program#partnerize-form"
              className={styles.whyCtasButton}
            >
              Get In Touch
            </Link>
          </div>
        </div>
      </Row>
    </section>
  )
}

export default Features
