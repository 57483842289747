import React from "react"
import clsx from "clsx"

import FormInputText from "@src/components/FormInputText"
import usePartnerizeForm from "../../hooks/usePartnerizeForm"

import * as styles from "./Form.module.scss"

const Form = ({ location }) => {
  const { form, setForm, submit, loading, errors } = usePartnerizeForm(location)

  return (
    <form className={styles.form} onSubmit={submit} id="partnerize-form">
      <FormInputText
        label="Name"
        value={form.name}
        onChange={setForm("name")}
        error={errors.name}
      />
      <FormInputText
        label="Email"
        type="email"
        value={form.email}
        onChange={setForm("email")}
        error={errors.email}
      />
      <FormInputText
        label="Website"
        value={form.website}
        onChange={setForm("website")}
        error={errors.website}
      />
      <FormInputText
        label="Contact Number"
        value={form.phone}
        onChange={setForm("phone")}
        error={errors.phone}
      />

      <button
        type="submit"
        className={clsx({ [styles.success]: loading === "SUCCESS" })}
        disabled={loading === "LOADING"}
      >
        {loading === "SUCCESS" ? "Submitted" : "Submit"}
      </button>
    </form>
  )
}

export default Form
