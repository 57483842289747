import React from "react"

import Layout from "@src/components/Layout"
import Splash from "../Features/components/Splash"
import SplashForm from "./components/Form"
import Features from "./sections/Features"
import Service from "./sections/Service"
import Technology from "./sections/Technology"
import Join from "./sections/Join"

import * as styles from "./PartnerProgram.module.scss"
import ClientStrip from "../../V3/Home/sections/ClientStrip"

const PartnerProgram = ({ location }) => {
  return (
    <Layout
      title="Partner Program"
      description="Excited to collaborate 
      with you via Canonic’s Partnership Program"
      url="https://canonic.dev/partner-program"
      //   ogImage={OG}
    >
      <Splash
        title={
          <>
            Excited to collaborate with you via{" "}
            <span className={styles.span}>Canonic’s Partnership Program</span>
          </>
        }
        subtitle="Provide custom internal tools and workflows to automate tedious business processes and drive unprecedented efficiency for your customers. Additionally,  expand your reach and potential with access to our vast network of over 7000+ customers"
        ctas={<></>}
        illustration={<SplashForm location={location} />}
      />
      <ClientStrip />
      <Features />
      <Service />
      <Technology />
      <Join />
    </Layout>
  )
}

export default PartnerProgram
