// extracted by mini-css-extract-plugin
export var automatedWorkflows = "ImageRow-module--automatedWorkflows--pK3DV";
export var automatedWorkflowsFeatures = "ImageRow-module--automatedWorkflowsFeatures--3Bgn-";
export var automatedWorkflowsPreview = "ImageRow-module--automatedWorkflowsPreview--3qad5";
export var automatedWorkflowsRow = "ImageRow-module--automatedWorkflowsRow--3u3TF";
export var automatedWorkflowsSmallTitle = "ImageRow-module--automatedWorkflowsSmallTitle--34K6a";
export var automatedWorkflowsSubtitle = "ImageRow-module--automatedWorkflowsSubtitle--3_Tbs";
export var automatedWorkflowsText = "ImageRow-module--automatedWorkflowsText--2JsXX";
export var automatedWorkflowsTitle = "ImageRow-module--automatedWorkflowsTitle--2gwKw";
export var borderless = "ImageRow-module--borderless--5gjWd";
export var icon = "ImageRow-module--icon--3A9z0";
export var reverse = "ImageRow-module--reverse--1kxCR";
export var screenshot = "ImageRow-module--screenshot--1TrOC";