import React from "react"
import * as styles from "./ImageRow.module.scss"

export default function ImageRow({
  title,
  subtitle,
  reverse,
  screenshot,
  icon: Icon,
  features,
  children,
  ctas,
  borderless,
  className,
}) {
  const reverseClassName = reverse ? styles.reverse : ""
  const borderlessClassName = borderless ? styles.borderless : ""
  const child = [
    <figure
      className={`${styles.automatedWorkflowsPreview} ${borderlessClassName}`}
    >
      {children ? (
        children
      ) : screenshot ? (
        <img className={styles.screenshot} src={screenshot} />
      ) : Icon ? (
        typeof Icon === "string" ? (
          <img className={styles.icon} src={Icon} />
        ) : (
          <Icon className={styles.icon} />
        )
      ) : null}
    </figure>,
    <div className={styles.automatedWorkflowsText}>
      <h3 className={styles.automatedWorkflowsSmallTitle}>{title}</h3>
      <p className={styles.automatedWorkflowsSubtitle}>{subtitle}</p>
      <div>{ctas}</div>
    </div>,
  ]

  return (
    <div
      className={`${styles.automatedWorkflowsRow} ${reverseClassName} ${className}`}
    >
      {reverse ? [...child.reverse()] : child}
    </div>
  )
}
