import React from "react"
import DotBG from "../../../components/DotBG"
import Row from "../../../components/Row"
import useMobile from "../../../utils/isMobile"
import getAppUrl from "@src/utils/getAppUrl"
import * as styles from "./Splash.module.scss"

const Splash = ({
  title,
  subtitle,
  withDotBG,
  illustration,
  reverseOnMobile,
  ctas,
  buttonText = "Try for free",
}) => {
  const isMobile = useMobile()
  const appUrl = getAppUrl.hook()
  const reverseOnMobileClassName = reverseOnMobile ? styles.reverseOnMobile : ""
  return (
    <Row className={styles.row}>
      {withDotBG && (
        <DotBG
          height={isMobile ? "133%" : "150%"}
          offset={isMobile ? "-7%" : "-100%"}
        />
      )}
      <section className={`${styles.splash} ${reverseOnMobileClassName}`}>
        <div className={styles.splashText}>
          <h1 className={styles.splashTextTitle}>{title}</h1>
          <p className={styles.splashTextSubtitle}>{subtitle}</p>
          <div className={styles.splashTextCtas}>
            {!ctas ? (
              <a href={appUrl} target="_blank" rel="noopener noreferrer">
                <button borderless className={styles.splashTextCtasButton}>
                  {buttonText}
                </button>
              </a>
            ) : (
              ctas
            )}
          </div>
        </div>
        <div className={styles.splashIllustration}>{illustration}</div>
      </section>
    </Row>
  )
}

export default Splash
