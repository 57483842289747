import React from "react"
import getContactUrl from "@src/utils/getContactUrl"

const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const STATES = {
  NOT_LOADING: "NOT_LOADING",
  LOADING: "LOADING",
  SUCCESS: "SUCCESS",
}

export default function usePartnerizeForm(location) {
  const [errors, setErrors] = React.useState({})
  const [loading, setLoading] = React.useState(STATES.NOT_LOADING)
  const [form, set] = React.useState({
    email: "",
    phone: "",
  })

  const validate = React.useCallback(() => {
    const validationErrors = {}
    if (!form.email) {
      validationErrors.email = "Email is required"
    } else if (!EMAIL_REGEX.test(form.email)) {
      validationErrors.email = "Please enter a valid email"
    }

    setErrors(validationErrors)
    if (Object.keys(validationErrors).length) return false
    return true
  }, [form])

  const setForm = React.useCallback(
    key => e => {
      e.persist()
      if (key === "email") setErrors(e => ({ ...e, email: "" }))
      set(form => ({ ...form, [key]: e.target.value }))
    },
    []
  )
  console.log(`${getContactUrl(location)}/partner-program`)
  const submit = React.useCallback(
    e => {
      e.preventDefault()
      if (!validate()) return false
      setLoading(STATES.LOADING)
      return fetch(`${getContactUrl(location)}/partner-program`, {
        headers: { "content-type": "application/json" },
        method: "POST",
        body: JSON.stringify({
          ...form,
        }),
      }).then(setLoading(STATES.SUCCESS))
    },
    [form, validate, location]
  )

  return {
    form,
    setForm,
    submit,
    loading,
    errors,
  }
}
