import React from "react"
import * as styles from "./Column.module.scss"

const booleanClass = (bool, className) => (bool ? className : "")
export default function Column({
  title,
  description,
  icon: Icon,
  style,
  horizontal,
  readmore,
}) {
  return (
    <div
      style={style}
      className={`${styles.column} ${booleanClass(
        horizontal,
        styles.horizontal
      )}`}
    >
      {Icon && (
        <figure className={styles.columnFigure}>
          {typeof Icon === "string" ? (
            <img className={styles.columnFigureImg} src={Icon} />
          ) : (
            <Icon />
          )}
        </figure>
      )}
      <div className={styles.columnText}>
        <h3 className={styles.columnTitle}>{title}</h3>
        <p className={styles.columnSubtitle}>{description}</p>
       {readmore &&  <a href={readmore} className={styles.columnLink}>Read More →</a>}
      </div>
    </div>
  )
}

Column.Wrap = ({ children, numPerRow = 3 }) => (
  <div className={styles.row}>
    {React.Children.map(children, child =>
      React.cloneElement(child, {
        style: { flexBasis: `calc(${(1 / numPerRow) * 100}% - 108px)` },
      })
    )}
  </div>
)
